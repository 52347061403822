/* reset */

.CheckboxRoot {
    background-color: transparent;
    width: 24px;
    height: 24px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--color-primary);
    cursor: pointer;
}

.CheckboxRoot:hover {
    background-color: var(--color-grey-6);
}

.CheckboxIndicator {
    color: var(--color-grey-6);
}

.CheckboxRoot[data-state="checked"] {
    background-color: var(--color-primary);
}


.Label {
    color: black;
    padding-left: 15px;
    font-size: 15px;
    line-height: 1;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.CheckboxFlex {
    display: flex;
    width: 100%;
    cursor: pointer;
}

.Action {
    width: calc(100% - 32px);
        position: absolute;
        bottom: calc(0px + env(keyboard-inset-height, 0px));
        left: 16;
        margin: auto auto;
        margin-bottom: 0px;
        text-align: left;
        display: flex;
        flex-direction: column;
}

.reasonContainer{
    width: 100%;
    overflow-y: scroll;
    padding: 10px;
    border: 1px solid var(--color-grey-4);
    border-radius: 8px;
    position: relative;
}

.pageContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100dvh - 40px);
    text-align: left;
}
.afiliateContainer{
    padding: 24px 16px 0px 16px;
}

.Bottom {
    width: calc(100% - 32px);
    position: absolute;
    bottom: calc(0px + env(keyboard-inset-height, 0px));
    margin-bottom: 12px;
    text-align: center;
}